.navBar {
  position: fixed;
  z-index: 1000;
  top: 0px;
  width: 100%;
}

.section {
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full width */
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
  z-index: 1;
}

.centered_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust this based on the container height */
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: rgba(15, 44, 90, 1);
  /* background: linear-gradient(180deg, #73e98c 9.62%, #53c16a 86.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.pageBar {
  position: fixed;
  top: 30%;
  left: 24px;
  z-index: 1000;
}

.verticalMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.pageText {
  display: flex;
  z-index: 10001;
  font-size: 11px;
  margin-left: 0.7rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: rgba(128, 128, 128, 1);
}

.menuItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px; /* Adjust the spacing */
  text-align: center;
  position: relative;
  z-index: 1001;
  justify-content: center;
  background-color: rgba(247, 245, 243, 1);
  font-size: 10px;
}
.icon {
  display: flex;
  background-color: rgba(247, 245, 243, 1); /* Default background color */
  border-radius: 50%;
  padding: 15px;
  height: 40px;
  width: 40px;
  align-items: center;
  border: 0.6px solid rgba(255, 255, 255, 1);
  box-shadow: 8px 8px 24px 0px rgba(174, 174, 192, 0.24);
  box-shadow: -8px -8px 24px 0px rgba(255, 255, 255, 1);
}

.iconActive {
  display: flex;
  border-radius: 50%;
  padding: 15px;
  height: 40px;
  width: 40px;
  align-items: center;
  border: 0.6px solid rgba(255, 255, 255, 1);
  box-shadow: 8px 8px 24px 0px rgba(174, 174, 192, 0.24);
  box-shadow: -8px -8px 24px 0px rgba(255, 255, 255, 1);
  background-color: rgba(
    76,
    185,
    99,
    1
  ); /* Green background for active state */
}

/* .verticalMenu::before {
  content: "";
  position: absolute;
  left: 50%;
  /* top: 10vh; 
  width: 1rem;
  height: 6vh;
  background-color: #e0e0e0; 
  z-index: 9991;
  background-color: #333;
} */

@media (max-width: 768px) {
  .verticalMenu {
    flex-direction: row;
  }

  .verticalMenu::before {
    height: 2px;
    width: 100%;
    top: auto;
    left: 0;
    right: 0;
  }

  .menuItem {
    flex-direction: row;
    align-items: center;
  }

  .icon {
    margin-right: 10px;
    margin-bottom: 0;
  }
}

/* Add to your CSS file or inline */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #4caf50;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.downloadLabel {
  cursor: pointer;
  position: relative;
  display: flex; /* Ensures the icon is centered */
  align-items: center;
  justify-content: center;
  width: 25px; /* Set width to make it circular */
  height: 25px; /* Equal to width for a perfect circle */
  border-radius: 50%; /* Makes it a circle */
  background-color: #4d56e3; /* Button background color */
  color: white;
  margin-right: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
  transition: transform 0.2s ease; /* Add a hover effect */
}

.downloadLabel:hover {
  transform: scale(1.2); /* Slight zoom effect on hover */
}

.downloadLabel:hover::after {
  content: attr(data-tooltip); /* Tooltip content from data-tooltip */
  position: absolute;
  bottom: 120%; /* Position the tooltip above the circle */
  left: 50%; /* Center align */
  transform: translateX(-50%);
  background-color: #333; /* Tooltip background */
  color: white;
  padding: 3px 5px;
  border-radius: 4px;
  font-size: 9px;
  white-space: nowrap;
  margin-bottom: 5px; /* Space between button and tooltip */
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional tooltip shadow */
}

.left_container {
  display: flex;
  width: 40%;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.4rem;
  font-size: 11px;
  margin-bottom: 2rem;
  height: 180%;
}

.map_container {
  height: 60%;
}

.right_container {
  margin-left: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 80%;
  height: 210%;
}

@media (max-height: 1125px) {
  .right_container,
  .left_container {
    height: 165%;
  }
  .Fontss {
    font-size: 16px;
  }
}
@media (max-height: 1025px) {
  .scrollable_list {
    /* background-color: pink; */
    max-height: 255px;
    font-size: 12px;
  }
  .Fontss,
  .downloadLabel {
    font-size: 13px;
  }
  .card,
  .cardContent {
    font-size: 16px;
  }
  .scrollable_list_advisory {
    /* background-color: pink; */
    max-height: 300px;
    font-size: 14px;
  }
  .Advisory {
    font-size: 16px;
  }
  .bottom_stats {
    margin-top: 3vh;
    font-size: 16px;
  }
  .Yield_Text {
    font-size: 17px;
  }
  .Yield_Number {
    font-size: 18px;
  }
}

@media (max-height: 925px) {
  .scrollable_list {
    /* background-color: #4cb963; */
    max-height: 205px;
    font-size: 11px;
  }
  .Fontss,
  .downloadLabel {
    font-size: 12px;
  }
  .card,
  .cardContent {
    font-size: 14px;
  }
  .scrollable_list_advisory {
    /* background-color: #4cb963; */
    max-height: 270px;
    font-size: 12px;
  }
  .Advisory {
    font-size: 15px;
  }
  .Revenue_Design {
    margin-top: 30px;
  }
  .Yield_Text {
    font-size: 17px;
  }
  .Yield_Number {
    font-size: 19px;
  }
}

@media (max-height: 825px) {
  .scrollable_list {
    /* background-color: red; */
    max-height: 175px;
    font-size: 10px;
  }
  .Fontss,
  .downloadLabel {
    font-size: 11px;
  }
  .card,
  .cardContent {
    font-size: 13px;
  }
  .scrollable_list_advisory {
    /* background-color: red; */
    max-height: 230px;
    font-size: 11px;
  }
  .bottom_stats {
    margin-top: 3vh;
    font-size: 12px;
  }
  .Advisory {
    font-size: 12px;
  }
  .Revenue_Design {
    margin-top: 10px;
  }
  .Yield_Text {
    font-size: 13px;
  }
  .Yield_Number {
    font-size: 15px;
  }
}

@media (max-height: 725px) {
  .scrollable_list {
    /* background-color: aqua; */
    max-height: 155px;
    font-size: 10px;
  }
  .Fontss,
  .downloadLabel {
    font-size: 9px;
  }
  .card,
  .cardContent {
    font-size: 12px;
  }
  .Advisory {
    font-size: 13px;
  }
  .scrollable_list_advisory {
    /* background-color: aqua; */
    max-height: 210px;
    font-size: 9px;
  }
  .Advisory {
    font-size: 11px;
  }
  .bottom_stats {
    margin-top: 3vh;
    font-size: 10px;
  }
  .Revenue_Design {
    margin-top: 5px;
  }
  .Yield_Text {
    font-size: 11px;
  }
  .Yield_Number {
    font-size: 12px;
  }
}

@media (max-height: 625px) {
  .scrollable_list {
    /* background-color: cornflowerblue; */
    max-height: 120px;
    font-size: 7px;
  }
  .Fontss,
  .downloadLabel {
    font-size: 8px;
  }
  .card,
  .cardContent {
    font-size: 9px;
  }
  .Advisory {
    font-size: 9px;
  }
  .scrollable_list_advisory {
    /* background-color: aqua; */
    max-height: 130px;
    font-size: 8px;
  }
  .Advisory {
    font-size: 9px;
  }
  .bottom_stats {
    font-size: 7px;
    margin-bottom: 10px;
  }
  .Revenue_Design {
    margin-top: 5px;
  }
  .Yield_Text {
    font-size: 8px;
  }
  .Yield_Number {
    font-size: 9px;
  }
}

.multiSelectContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #f7f5f3;
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
}

.multiSelectContainer:hover {
  transform: scale(1.02); /* Slight zoom effect on hover */
}

.multiSelectContainer .multiSelect {
  width: 100%;
  background-color: transparent; /* Keep the background transparent */
  border: none; /* Remove default border */
  font-family: Arial, sans-serif; /* Optional custom font */
}

/* Add this to your CSS file or module */
.legend_labels {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  color: black;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.legend_label span {
  text-align: center;
  flex: 1;
}

/* Large screens (1025px to 1125px) */
@media (max-height: 1125px) and (min-height: 1026px) {
  .legend_labels {
    font-size: 13px;
    /* background-color: red; */
  }
}

/* Medium-large screens (925px to 1025px) */
@media (max-height: 1025px) and (min-height: 926px) {
  .legend_labels {
    font-size: 14px;
    /* background-color: lightcoral; */
  }
}

/* Medium screens (825px to 925px) */
@media (max-height: 925px) and (min-height: 826px) {
  .legend_labels {
    font-size: 12px;
    /* background-color: lightgoldenrodyellow; */
  }
}

/* Small screens (725px to 825px) */
@media (max-height: 825px) and (min-height: 726px) {
  .legend_labels {
    font-size: 9px;
    /* background-color: lightpink; */
  }
}

/* Extra-small screens (625px to 725px) */
@media (max-height: 725px) and (min-height: 626px) {
  .legend_labels {
    font-size: 8px;
    /* background-color: lightgray; */
  }
}

/* Very small screens (max-height <= 625px) */
@media (max-height: 625px) {
  .legend_labels,
  .LegendToolTip:hover::after {
    font-size: 7px;
    /* background-color: lightsteelblue; */
  }
}

.LegendToolTip:hover::after {
  font-family: "Red Hat Display";
  z-index: 10001;
  content: attr(data-tooltip); /* Tooltip content from data-tooltip */
  position: absolute;
  bottom: 100%; /* Position the tooltip above the circle */
  left: 115%; /* Center align */
  transform: translateX(-50%);
  background-color: #333; /* Tooltip background */
  color: white;
  padding: 5px 7px; /* Padding for tooltip */
  border-radius: 4px; /* Rounded tooltip corners */
  font-size: 9px;
  white-space: nowrap; /* Prevent text wrapping */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional shadow */
  overflow: visible; /* Ensure tooltip is visible */
  font-size: 12px;
  background-color: #868686;
}
