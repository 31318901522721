.right_container {
  display: flex;
  background: rgba(247, 245, 243, 1);
  width: 69vw;
  height: 71vh;
  border-radius: 20px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0rem;
  padding: 1rem;
}
.left_container {
  display: flex;
  background: rgba(247, 245, 243, 1);
  width: 28vw;
  height: 71vh;
  padding-top: 0.9rem;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 1rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

/* SelectedParameters.module.css */
.parameterChip {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 20px;
  background-color: #f5f5f5;
  margin-right: 8px;
  margin-bottom: 8px;
  font-family: "Roboto", sans-serif;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.parameterText {
  margin-left: 8px;
  font-size: 14px;
  font-weight: 500;
}

.iconPlaceholder {
  width: 16px;
  height: 16px;
  background-color: #e0e0e0;
  border-radius: 50%;
}

.closeButton {
  padding: 4px;
  margin-left: 8px;
  cursor: pointer;
}
