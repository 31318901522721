.Parameter-box {
  display: flex;
  width: 15vw;
  padding: 1rem;
  height: 16vh;

  box-shadow: 1px 1px 8px 0px rgba(255, 255, 255, 1),
    1px 1px 0px 0px rgba(32, 32, 32, 0.08);
  align-items: center;
  color: rgba(128, 128, 128, 1);
  flex-direction: column;
  gap: 0.5rem;
  align-items: start;
  overflow: hidden;
  cursor: pointer;
}

.Crop-Name {
  font-size: 11px;
}

.Price {
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
}
