@import url("@fontsource/red-hat-display");
* {
  font-family: "Red Hat Display", sans-serif;
}

.Outer_div {
  background: none;
  border: 0px solid rgba(255, 255, 255, 0.23);
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; /* Neomorphic shadow */
  display: flex;
  justify-content: flex-start;
  border-radius: 30px;
  flex-direction: column;
  gap: 3rem;
  padding-top: 1.5rem;
  padding-left: 2.5rem;
  padding-right: 2rem;
  width: 95%;
  margin-left: 3rem;
  position: relative;
}

.scrollable {
  background: none;
  display: flex;
  justify-content: flex-start;
  border-radius: 30px;
  flex-direction: column;
  gap: 3rem;
  padding: 1rem;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}

/* Custom scrollbar styles */
.scrollable::-webkit-scrollbar {
  width: 5px;
  /* background-color: white; */
}

.scrollable::-webkit-scrollbar-thumb {
  border-radius: 10px; /* Border radius for the scrollbar thumb */
  background: linear-gradient(
    to right,
    #808080 -11.53%,
    rgba(71, 91, 75, 0.47) 100%
  );
  margin-right: "100px";
}

.scrollable::-webkit-scrollbar-track {
  background-color: rgba(227, 237, 247, 1);
  margin-top: 9rem;
  margin-bottom: 9rem;
  border-radius: 8px;
  border: 1px solid;

  border-image-source: linear-gradient(
    122.51deg,
    rgba(214, 227, 243, 0.66) 16.62%,
    rgba(255, 255, 255, 0.66) 56.74%
  );
}

/* Media query for screens with height less than 600px */
@media (max-height: 1125px) {
  .scrollable {
    height: 70vh;
    /* background-color: violet; */
  }

  .scrollable::-webkit-scrollbar-track {
    margin-top: 19rem;
    margin-bottom: 19rem;
    border-radius: 8px;
    border: 1px;
  }
}
@media (max-height: 1025px) {
  .scrollable {
    height: 66vh;
    /* background-color: pink; */
  }
  .scrollable::-webkit-scrollbar-track {
    margin-top: 18rem;
    margin-bottom: 18rem;
    border-radius: 8px;
    border: 1px;
  }
  .scrollable::-webkit-scrollbar {
    width: 7px;
  }
}

@media (max-height: 925px) {
  .scrollable {
    height: 66vh;
    /* background-color: #4cb963; */
  }
  .scrollable::-webkit-scrollbar-track {
    margin-top: 16rem;
    margin-bottom: 16rem;
    border-radius: 8px;
    border: 1px;
  }
}

@media (max-height: 825px) {
  .scrollable {
    height: 62vh;
    /* background-color: red; */
  }

  .scrollable::-webkit-scrollbar-track {
    margin-top: 13rem;
    margin-bottom: 13rem;
    border-radius: 8px;
    border: 1px;
  }
}
@media (max-height: 725px) {
  .scrollable {
    height: 55vh;
    /* background-color: aqua; */
  }

  .scrollable::-webkit-scrollbar-track {
    margin-top: 10rem;
    margin-bottom: 10rem;
    border-radius: 8px;
    border: 1px;
  }
}
@media (max-height: 625px) {
  .scrollable {
    height: 48vh;
    /* background-color: cornflowerblue; */
  }

  .scrollable::-webkit-scrollbar-track {
    margin-top: 7rem;
    margin-bottom: 6rem;
    border-radius: 8px;
    border: 1px;
  }
}
@media (max-height: 525px) {
  .scrollable {
    height: 28vh;
    /* background-color: yellowgreen; */
  }
}

.loader_container {
  display: flex;
  flex-direction: column; /* Aligns children vertically */
  justify-content: center;
  align-items: center;
  height: 100vh; /* Use the full height of the viewport */
  width: 100vw; /* Use the full width of the viewport */
  text-align: center; /* Centers the text horizontally */
}

.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader_text {
  margin-top: 20px; /* Space between loader and text */
  font-size: 22px;
  color: #666666; /* Mid grey, but choose a color that fits your design */
  font-weight: bold;
}

.centered_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust this based on the container height */
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: rgba(15, 44, 90, 1);
  /* background: linear-gradient(180deg, #73e98c 9.62%, #53c16a 86.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.empty_projects {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust this based on the container height */
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: rgba(15, 44, 90, 1);
  /* background: linear-gradient(180deg, #73e98c 9.62%, #53c16a 86.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.RunAnalysis {
  cursor: pointer;
  position: relative;
  display: flex; /* Ensures the icon is centered */
  align-items: center;
  justify-content: center;
  width: 25px; /* Set width to make it circular */
  height: 25px; /* Equal to width for a perfect circle */
  border-radius: 50%; /* Makes it a circle */
  background: linear-gradient(180deg, #c5e0ff 9.62%, #5ba7ff 86.54%);
  color: white;
  margin-right: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
  transition: transform 0.2s ease; /* Add a hover effect */
  z-index: 10001;
}

.RunAnalysis:hover {
  transform: scale(1.2); /* Slight zoom effect on hover */
}

.View:hover::after,
.RunAnalysis:hover::after {
  z-index: 10001;
  content: attr(data-tooltip); /* Tooltip content from data-tooltip */
  position: absolute;
  bottom: 80%; /* Position the tooltip above the circle */
  left: 65%; /* Center align */
  transform: translateX(-50%);
  background-color: #333; /* Tooltip background */
  color: white;
  padding: 3px 5px; /* Padding for tooltip */
  border-radius: 4px; /* Rounded tooltip corners */
  font-size: 9px;
  white-space: nowrap; /* Prevent text wrapping */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional shadow */
  overflow: visible; /* Ensure tooltip is visible */
}

.View {
  cursor: pointer;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  text-transform: none;
  width: 40px; /* Set width to make it circular */
  height: 25px; /* Equal to width for a perfect circle */
  border-radius: 50px; /* Makes it a circle */
  background: linear-gradient(
    180deg,
    #ffebd3 9.62%,
    #ffad5b 86.54%
  ); /* Button background color */
  color: white;
  font-size: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.View:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
}
