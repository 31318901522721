.App {
  text-align: center;
  font-family: "Red Hat Display", sans-serif;
}
.leaflet-container {
  height: 100%;
  border-radius: 0.6rem;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body {
  font-family: "Red Hat Display", sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root {
  font-family: "Red Hat Display"; /* replace with your desired font family */
}

.uploaded-files-container {
  margin-top: 20px;
  font-size: 14px;
  margin: 20px;
  color: #808080;
  height: 40vh;
  overflow-y: scroll;
  overflow-x: clip;
  padding-right: 1rem;
}

.uploaded-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.uploaded-file span {
  margin-right: 10px;
}

uploaded-file div {
  flex-grow: 1;
}

.uploaded-file:hover {
  background-color: #f0f0f0;
}

.uploaded-file button {
  background: none;
  border: none;
  cursor: pointer;
  color: red;
}

.excel-container {
  z-index: 1000;
  background-color: #282c34;
  position: absolute;
  top: 14rem;
  left: 40rem;
}

.Spreadsheet {
  --background-color: white;
  --text-color: black;
  --readonly-text-color: rgba(0, 0, 0, 0.4);
  --outline-color: #4285f4;
  --outline-background-color: rgba(160, 195, 255, 0.2);
  --border-color: hsl(2deg, 0%, 91%);
  --header-background-color: rgba(0, 0, 0, 0.04);
  --elevation: 0 2px 5px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: visible;
  background: var(--background-color);
  color: var(--text-color);
  display: inline-block;
  z-index: 1000;
}

.Spreadsheet__data-viewer {
  color: black;
}
.leaflet-control-zoom {
  display: none !important;
}
