/* 
.right-container {
    display: flex;
    background: rgba(247, 245, 243, 1);
    width: 64vw;
    height: 71vh;
    border-radius: 20px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  } */
.leaflet-container {
  height: 100%;
  border-radius: 0.6rem;
}

.left_container {
  display: flex;
  background: rgba(247, 245, 243, 1);
  width: 28vw;
  height: 71vh;
  padding-top: 0.9rem;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 1rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.left_container_parameterselection {
  display: flex;
  background: rgba(247, 245, 243, 1);

  padding-top: 0.9rem;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 1rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  margin-bottom: 1rem;
  height: 78vh;
  padding-bottom: 1rem;
  width: 30vw;
}

.upper_container_dateselection {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 30vw;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  margin-bottom: 1.3rem;
  height: 78vh;
  border-radius: 1rem;
  background: rgba(247, 245, 243, 1);
}

.lower_container_dateselection {
  display: flex;
  background: rgba(247, 245, 243, 1);
  width: 30vw;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 1rem;
}
.continue_dateselection {
  display: flex;
  justify-content: center;
  margin-bottom: 0.8rem;
}

.map_container {
  display: flex;
  background: rgba(247, 245, 243, 1);
  width: 30vw;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 1rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  flex-grow: 2;
}
.continue_button {
  width: 26vw;
  height: 6vh;
  border-radius: 12px;
  font-family: "Red Hat Display";
  display: flex;
  text-transform: none;
  align-items: center;
  justify-content: center;
  background-blend-mode: overlay;
  background: linear-gradient(180deg, #73e98c 9.62%, #53c16a 86.54%);
  box-shadow: none;
  border: 0px;
  color: white;
  transition: border-color 0.3s;
  font-weight: 800px; /* This should be just '800' instead of '800px' */
  font-size: 12px;
}

.DrawTools_Container {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  padding: 4px 11px;
  top: 2em;
  left: 2em;
  background-color: rgba(247, 245, 243, 1);
  color: black;
  z-index: 400;
  border: none;
  cursor: pointer;
  border-radius: 2em;
  box-shadow: 8px 8px 8px 0px rgba(131, 131, 131, 0.08) inset;
  box-shadow: -8px -8px 16px 0px rgba(255, 255, 255, 1) inset;
  gap: 0.2em;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.3rem;
  height: 1.7rem;
}

.rectangle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.3rem;
  height: 1.7rem;
}

.polygon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.3rem;
  height: 1.7rem;
}

.line {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.3rem;
  height: 1.7rem;
}

.ZoomControl_Container {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  padding: 4px 7px;
  gap: 0.2em;
  top: 2em;
  left: 16em;
  background-color: rgba(247, 245, 243, 1);
  color: black;
  z-index: 400;
  border: none;
  cursor: pointer;
  border-radius: 4em;
}

.leaflet-bar.leaflet-control-zoom {
  display: none;
}

.delete {
  display: flex;
  position: absolute;
  padding: 10px 11px;
  align-items: center;
  justify-content: center;
  top: 2em;
  left: 23em;
  background-color: rgba(247, 245, 243, 1);
  z-index: 400;
  border: none;
  cursor: pointer;
  border-radius: 3em;
  vertical-align: bottom;
}

/* .leaflet-draw-section { 
    display:none 
  } */

.right_container_parameters {
  display: flex;
  background: rgba(247, 245, 243, 1);
  width: 69vw;
  height: 71vh;
  border-radius: 20px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0rem;
  padding: 1rem;
}

.select_item {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  font-size: 14px;
  font-weight: 600;
}

.date {
  display: flex;
  padding: 1em;
  background-color: rgba(247, 245, 243, 1);
  align-items: center;
  margin-left: 0.7rem;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
}

.selector {
  position: relative;
  display: inline-block;
  font-size: 13px;
}

.selectDropdown {
  border-radius: 16px;
  padding: 4px 2px;
  border: 0;
  outline: 1px solid #808080;
  border-right: 10px solid transparent;
  font-weight: 600;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  font-size: 13px;
}

.selectDropdown.selected {
  outline-color: rgba(76, 185, 99, 1);
  color: rgba(76, 185, 99, 1);
  font-size: 14px;
  background: linear-gradient(180deg, #73e98c 9.62%, #53c16a 86.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.defaultButton {
  background: linear-gradient(180deg, #b7b7b7 9.62%, #7b7b7b 86.54%);
  /* box-shadow: 4px 4px 36px 0px rgba(0, 0, 0, 0.33),
    -8px -8px 36px 0px rgba(255, 255, 255, 0.48); */
  cursor: not-allowed;
}

.selectedButton {
  background: linear-gradient(180deg, #73e98c 9.62%, #53c16a 86.54%);
  box-shadow: 0px -4px 8px white, 0px 4px 8px rgba(0, 0, 0, 0.2),
    2.571px 2.571px 5.143px 0px rgba(174, 174, 192, 0.4),
    -1.714px -1.714px 5.143px 0px rgba(255, 255, 255, 1);
}

.multiselect {
  padding: 0.8rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
