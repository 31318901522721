@import url("@fontsource/red-hat-display");
* {
  font-family: "Red Hat Display", sans-serif;
}

.new_project_flexbox {
  display: flex;
  justify-content: space-between;
  padding-left: 44px;
  padding-top: 60px;
  padding-right: 44px;
}

.middle_div {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1.8rem;
  gap: 1rem; /* Adds space between the divs */
  margin-left: 1.4rem;
}

@media (max-height: 1125px) {
  .middle_div {
    height: 68vh;
    /* background-color: violet; */
  }
}
@media (max-height: 1025px) {
  .middle_div {
    height: 64vh;
    /* background-color: pink; */
  }
}

@media (max-height: 825px) {
  .middle_div {
    height: 60vh;
    /* background-color: red; */
  }
}
@media (max-height: 725px) {
  .middle_div {
    height: 53vh;
    /* background-color: aqua; */
  }
}
@media (max-height: 625px) {
  .middle_div {
    height: 46vh;
    /* background-color: cornflowerblue; */
  }
}
@media (max-height: 525px) {
  .middle_div {
    height: 26vh;
    /* background-color: yellowgreen; */
  }
}

.flex_item {
  flex: 0 0 32%; /* Adjust this value to control the width of each item */
  box-sizing: border-box;
  margin-bottom: 1rem;
}

.main_div {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: 0px solid rgba(255, 255, 255, 0.23);
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  margin-top: 1rem;
  border-radius: 30px;
  padding: 1rem;
}

.calender_container {
  display: flex;
  width: 15vw;
  height: 6vh;
  border-radius: 10px; /* Match the border radius from the MUI code */
  box-shadow: 1px 1px 8px 0px rgba(255, 255, 255, 1),
    1px 1px 0px 0px rgba(32, 32, 32, 0.08);
  justify-content: space-between;
  border: 2px solid rgba(219, 219, 219, 1); /* Match the border style from the MUI code */
  align-items: center;
  padding: 1rem;
  color: black;
  cursor: pointer;
}

.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root {
  font-family: "Red Hat Display"; /* replace with your desired font family */
}
