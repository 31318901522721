.right_container {
  display: flex;
  background: rgba(247, 245, 243, 1);
  width: 64vw;
  height: 71vh;
  border-radius: 20px;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.left_container {
  display: flex;
  background: rgba(247, 245, 243, 1);
  width: 27vw;
  height: 71vh;
  padding: 2rem;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 1rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  margin-bottom: 2em;
  gap: 2em;
}

.billing_address {
  display: flex;
  width: 20vw;
  padding-left: 2rem;
  height: 8vh;
  border-radius: 11px;
  box-shadow: 1px 1px 8px 0px rgba(255, 255, 255, 1),
    1px 1px 0px 0px rgba(32, 32, 32, 0.08);
  border: 1px solid rgba(219, 219, 219, 1);
  align-items: center;
  color: rgba(128, 128, 128, 1);
}

.proceed_button {
  width: 26vw;
  height: 6vh;
  border-radius: 12px;
  font-family: "Red Hat Display";
  display: flex;
  text-transform: none;
  align-items: center;
  justify-content: center;
  background-blend-mode: overlay;
  background: linear-gradient(180deg, #73e98c 9.62%, #53c16a 86.54%);
  box-shadow: none;
  border: 0px;
  color: white;
  transition: border-color 0.3s;
  font-weight: 800px; /* This should be just '800' instead of '800px' */
  font-size: 12px;
}
