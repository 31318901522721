@import url("@fontsource/red-hat-display");

* {
  font-family: "Red Hat Display";
}

.Infra {
  display: flex;
  padding: 3em;
}

.RightDiv {
  width: 500px;
  height: max-content;
  padding-top: 5rem;
}

@media screen and (max-width: 900px) {
  .Infra {
    padding: 1rem;
  }
}

@media screen and (max-height: 775px) {
  .RightDiv {
    padding-top: 4rem;
  }
}
@media screen and (max-height: 750px) {
  .RightDiv {
    padding-top: 3rem;
  }
}
@media screen and (max-height: 720px) {
  .RightDiv {
    padding-top: 2rem;
  }
}
@media screen and (max-height: 690px) {
  .RightDiv {
    padding-top: 0.8rem;
  }
}

.background_container {
  position: relative;
  background-image: url("../Asset/Images/Login_background.jpg"); /* Set the background image path */
  background-size: cover; /* Cover the entire container */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: center; /* Center the background image */
  position: fixed; /* Fix the background image in place */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 24px;
}

.Forgot {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
