.circle-container {
  position: relative;
  width: 24px; /* smaller container width */
  height: 24px; /* smaller container height */
}

/* Outer circle with pulsing animation */
.outer-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px; /* match container size */
  height: 24px; /* match container size */
  border-radius: 50%;
  background-color: rgba(11, 152, 85, 0.24);
  animation: pulse 1.5s infinite alternate;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(1.1);
    opacity: 0.5;
  }
}

/* Middle circle (static) */
.middle-circle {
  position: absolute;
  top: 3.2px;
  left: 3.5px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: rgba(11, 152, 85, 0.47);
}

/* Inner circle (static) */
.inner-circle {
  position: absolute;
  top: 8px;
  left: 8.5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #0b9855;
}

/* Container that centers the timeline */
.timeline-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  width: 50%;
}

/* A wrapper for the line + markers */
.timeline-bar {
  position: relative;
  display: flex;
  align-items: center;
  /* Adjust the width as needed */
  width: 80%;
  max-width: 600px;
}

/* The thin horizontal line behind the markers */
.timeline-line {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #ccc;
  transform: translateY(-50%);
  z-index: 1;
}

/* Each year "marker" */
.timeline-year {
  position: relative;
  z-index: 2; /* place above the line */
  cursor: pointer;
  padding: 0.5rem;
  margin: 0 1rem;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid transparent;
  font-size: 0.875rem;
  color: #333;
  transition: all 0.3s ease;
}

/* Visual highlight for the active (selected) year */
.timeline-year.active {
  border-color: #4caf50; /* green border */
  color: #4caf50;
  font-weight: bold;
}

.timeline-svg {
  /* A little margin on top if you want */
  margin-top: 1rem;
}
