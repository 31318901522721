.card {
  position: relative;
  width: 14%; /* Adjust width as needed */
  padding: 18px;
  background-color: #f0ebca; /* Light background color */
  border-radius: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  box-shadow: -8px -8px 24px 0px #ffffff,
    8px 8px 24px 0px rgba(174, 174, 192, 0.24); /* Optional: adds a subtle shadow */
  font-size: 11px;
}

.cardContent {
  display: flex;
  z-index: 2;
  color: #5e5e5e;
  flex-direction: column;
}

.cropImage {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 30%; /* Adjust size as needed */
  height: auto;
  border-bottom-right-radius: 16px; /* Rounds the bottom corner of the image */
}

.Value1 {
  position: absolute;
  bottom: 0;
  left: 4;
  z-index: 1;
  background: white;
  backdrop-filter: blur(10px); /* Adjust the blur intensity as needed */
  opacity: 0.8;
  border-radius: 14px;
  height: 2em;
  width: 5em;
  margin-bottom: 1em;
  backdrop-filter: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.Value {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 4;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.69);
  border-radius: 14px;
  height: 2em;
  width: 5em;
  margin-bottom: 1em;
  font-weight: bold;
}

.upperRowDiv {
  display: flex;
  width: 20%;
  background-color: white;
  border-radius: 16px;
  font-size: 12px;
}

.scrollable_list_advisory {
  /* Set a fixed height or max-height to enable scrolling */
  max-height: 175px;

  /* Enable vertical scrolling */
  overflow-y: auto;

  /* Optional: Some padding for nice spacing */
  padding-right: 1.7em;
  font-size: 10px;
  padding-bottom: 1.2em;
  padding-top: 3.2rem;
}

.scrollable_list {
  /* Set a fixed height or max-height to enable scrolling */
  max-height: 155px;

  /* Enable vertical scrolling */
  overflow-y: auto;

  /* Optional: Some padding for nice spacing */

  padding-right: 1.7em;
  font-size: 11px;
  padding-bottom: 1.2em;
}

.scrollable_list::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* color on hover */
}

@media (max-height: 1125px) {
  .scrollable_list {
    /* background-color: violet; */
    max-height: 275px;
    font-size: 13px;
  }
  .Fontss {
    font-size: 14px;
  }
  .card,
  .cardContent {
    font-size: 17px;
  }
  .scrollable_list_advisory {
    /* background-color: violet; */
    max-height: 325px;
    margin-top: 2rem;
    font-size: 15px;
  }
  .Advisory {
    font-size: 17px;
  }
  .bottom_stats {
    margin-top: 5vh;
    font-size: 18px;
  }
  .Revenue_Design {
    margin-top: 40px;
  }
  .Yield_Text {
    font-size: 19px;
  }
  .Yield_Number {
    font-size: 20px;
  }
}
@media (max-height: 1025px) {
  .scrollable_list {
    /* background-color: pink; */
    max-height: 255px;
    font-size: 12px;
  }
  .Fontss {
    font-size: 13px;
  }
  .card,
  .cardContent {
    font-size: 16px;
  }
  .scrollable_list_advisory {
    /* background-color: pink; */
    max-height: 300px;
    font-size: 14px;
  }
  .Advisory {
    font-size: 16px;
  }
  .bottom_stats {
    margin-top: 3vh;
    font-size: 16px;
  }
  .Yield_Text {
    font-size: 17px;
  }
  .Yield_Number {
    font-size: 18px;
  }
}

@media (max-height: 925px) {
  .scrollable_list {
    /* background-color: #4cb963; */
    max-height: 205px;
    font-size: 11px;
  }
  .Fontss {
    font-size: 12px;
  }
  .card,
  .cardContent {
    font-size: 14px;
  }
  .scrollable_list_advisory {
    /* background-color: #4cb963; */
    max-height: 270px;
    font-size: 12px;
  }
  .Advisory {
    font-size: 15px;
  }
  .Revenue_Design {
    margin-top: 30px;
  }
  .Yield_Text {
    font-size: 17px;
  }
  .Yield_Number {
    font-size: 19px;
  }
}

@media (max-height: 825px) {
  .scrollable_list {
    /* background-color: red; */
    max-height: 175px;
    font-size: 10px;
  }
  .Fontss {
    font-size: 11px;
  }
  .card,
  .cardContent {
    font-size: 13px;
  }
  .scrollable_list_advisory {
    /* background-color: red; */
    max-height: 230px;
    font-size: 11px;
  }
  .bottom_stats {
    margin-top: 3vh;
    font-size: 12px;
  }
  .Advisory {
    font-size: 12px;
  }
  .Revenue_Design {
    margin-top: 10px;
  }
  .Yield_Text {
    font-size: 13px;
  }
  .Yield_Number {
    font-size: 15px;
  }
}

@media (max-height: 725px) {
  .scrollable_list {
    /* background-color: aqua; */
    max-height: 155px;
    font-size: 10px;
  }
  .Fontss {
    font-size: 9px;
  }
  .card,
  .cardContent {
    font-size: 12px;
  }
  .Advisory {
    font-size: 13px;
  }
  .scrollable_list_advisory {
    /* background-color: aqua; */
    max-height: 210px;
    font-size: 9px;
  }
  .Advisory {
    font-size: 11px;
  }
  .bottom_stats {
    margin-top: 3vh;
    font-size: 10px;
  }
  .Revenue_Design {
    margin-top: 5px;
  }
  .Yield_Text {
    font-size: 11px;
  }
  .Yield_Number {
    font-size: 12px;
  }
}

@media (max-height: 625px) {
  .scrollable_list {
    /* background-color: cornflowerblue; */
    max-height: 120px;
    font-size: 7px;
  }
  .Fontss {
    font-size: 8px;
  }
  .card,
  .cardContent {
    font-size: 9px;
  }
  .Advisory {
    font-size: 9px;
  }
  .scrollable_list_advisory {
    /* background-color: aqua; */
    max-height: 130px;
    font-size: 8px;
  }
  .Advisory {
    font-size: 9px;
  }
  .bottom_stats {
    font-size: 7px;
    margin-bottom: 10px;
  }
  .Revenue_Design {
    margin-top: 5px;
  }
  .Yield_Text {
    font-size: 8px;
  }
  .Yield_Number {
    font-size: 9px;
  }
}
